import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css"; // Import Bootstrap Icons
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

const Homepage = () => {
    return (
        <div className="container my-5">
            <div className="row align-items-center mb-4">
                <div className="col-12 col-md-8">
                    <h1 className="text-start">Willkommen</h1>
                    <p>
                        <strong>FamilienPlus</strong> ist für alle (werdenden) Eltern und
                        Familien mit Kindern bis drei Jahre im Landkreis Waldshut.
                    </p>
                    <p>
                        Hier findest du Angebote rund um <strong>Schwangerschaft</strong>,{" "}
                        <strong>Eltern werden</strong> und <strong>Familie sein</strong>.
                    </p>
                    <p>
                        Diese Seite soll dir dabei helfen, die passenden{" "}
                        <strong>Angebote</strong> für deine Situation und Fragen zu finden.
                    </p>
                </div>
                <div className="col-12 col-md-4 text-center text-md-start">
                    <img
                        src="/fhwaldshut.svg"
                        alt="FH Waldshut Logo"
                        className="img-fluid"
                        style={{ maxWidth: "333px" }}
                    />
                </div>
            </div>
            <p>
                Weitere Informationen und Links für die Suche findest du im{" "}
                <a href="/kompass">Kompass</a>.
            </p>
            <p>
                Damit du dir einen guten Überblick verschaffen kannst, findest du viele
                Anregungen und Infos auf den untenstehenden Seiten.
            </p>

            <div className="container my-3">
                <div className="row text-center my-4">
                    <div className="col-12 col-md-4 mb-3">
                        <Link to="/liste-anbieter" className="text-decoration-none">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <div className="icon-container mb-3">
                                        <i className="bi bi-building"></i>
                                    </div>
                                    <h5 className="card-title">Anbieter</h5>
                                    <p className="card-text">
                                        Hier findest du eine Liste aller Anbieter in deiner Nähe.
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <Link to="/angebote" className="text-decoration-none">
                            <div className="card shadow-sm">
                                <div className="card-body">
                                    <div className="icon-container mb-3">
                                        <i className="bi bi-binoculars"></i>
                                    </div>
                                    <h5 className="card-title">Angebote</h5>
                                    <p className="card-text">
                                        Hier findest du eine Liste aller Angebote für dich oder deine
                                        Familie.
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-12 col-md-4 mb-3">
                        <Link to="/kompass" className="text-decoration-none">
                            <div className="card shadow-sm ">
                                <div className="card-body">
                                    <div className="icon-container mb-3">
                                        <i className="bi bi-compass"></i>
                                    </div>
                                    <h5 className="card-title">Kompass</h5>
                                    <p className="card-text">
                                        Hier findest du umfangreiche Informationen zu
                                        unterschiedlichsten Themenbereichen.
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
            </div>

            <p>
                Zusätzliche <strong>Informationen</strong> rund um{" "}
                <strong>Schwangerschaft</strong>, das <strong>Eltern werden</strong> und
                das <strong>Familie sein</strong>, findest du auf der Seite{" "}
                <a href="https://www.fruehehilfen.de/" target="_blank" rel="noreferrer">
                    Nationales Zentrum Frühe Hilfen (NZFH)
                </a>
                ,{" "}
                <a href="https://www.familienplanung.de/" target="_blank" rel="noreferrer">
                    familienplanung.de
                </a>{" "}
                und im Bereich der Kindergesundheit auf{" "}
                <a
                    href="https://www.kindergesundheit-info.de/"
                    target="_blank"
                    rel="noreferrer"
                    className="link-primary"
                >
                    kindergesundheit-info.de
                </a>
                .
            </p>
            <p>
                Bei der Suche nach Fachleuten wie Hebammen, Fachärztinnen und Fachärzte
                können folgende Informationsseiten weiterhelfen:{" "}

            </p>
            <p><
                a
                href="https://www.arztsuche-bw.de/"
                target="_blank"
                rel="noreferrer"
            >
                Arztsuche der Kassenärztlichen Vereinigung Baden-Württemberg
            </a>.</p>
            <p>
                <a href="/2024-12-05-Flyer-Hebammenliste_2025.pdf" download>Hebammensuche</a>
            </p>
        </div>
    );
};

export default Homepage;