import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';

interface Offer {
  id: string;
  title: string;
  description: string;
  picture_url: string | null;
  category_id: string;
  target_group_id: string;
  organization_id: string;
  city: string;
  street: string;
  house_number: string;
}

interface Organization {
  id: string;
  name: string;
  logo_url: string | null;
  website: string | null;
  phone: string | null;
  address: string | null;
}

interface DropdownOption {
  id: string;
  name: string;
}

const OfferList: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract the organization_id from the URL query parameters on initial render
  const params = new URLSearchParams(location.search);
  const initialOrganizationId = params.get('organization_id') || '';

  const [offers, setOffers] = useState<Offer[]>([]);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [organizationMap, setOrganizationMap] = useState<{ [key: string]: Organization }>({});
  const [targetGroups, setTargetGroups] = useState<DropdownOption[]>([]);
  const [categories, setCategories] = useState<DropdownOption[]>([]);

  const [filteredOffers, setFilteredOffers] = useState<Offer[]>([]);
  const [selectedOrganization, setSelectedOrganization] = useState<string>(initialOrganizationId);
  const [selectedTargetGroup, setSelectedTargetGroup] = useState<string>('');
  const [selectedCategory, setSelectedCategory] = useState<string>('');

useEffect(() => {
  if (!selectedOrganization) {
    const params = new URLSearchParams(location.search);
    const orgId = params.get('organization_id') || '';
    setSelectedOrganization(orgId);
  }
}, []);

  useEffect(() => {
    axios
      .get('https://waldshut.progresspioneer.com/api/offers')
      .then((res) => setOffers(res.data))
      .catch((err) => console.error('Error fetching offers:', err));

    axios
      .get('https://waldshut.progresspioneer.com/api/organization')
      .then((res) => {
        setOrganizations(res.data);
        // Create a mapping from organization_id to organization data
        const orgMap: { [key: string]: Organization } = {};
        res.data.forEach((org: Organization) => {
          orgMap[org.id] = org;
        });
        setOrganizationMap(orgMap);
      })
      .catch((err) => console.error('Error fetching organizations:', err));

    axios
      .get('https://waldshut.progresspioneer.com/api/targetgroups')
      .then((res) => setTargetGroups(res.data))
      .catch((err) => console.error('Error fetching target groups:', err));

    axios
      .get('https://waldshut.progresspioneer.com/api/categories')
      .then((res) => setCategories(res.data))
      .catch((err) => console.error('Error fetching categories:', err));
  }, []);

  useEffect(() => {
    const filtered = offers.filter((offer) => {
      return (
        (selectedOrganization ? offer.organization_id === selectedOrganization : true) &&
        (selectedTargetGroup ? offer.target_group_id === selectedTargetGroup : true) &&
        (selectedCategory ? offer.category_id === selectedCategory : true)
      );
    });
    setFilteredOffers(filtered);
  }, [offers, selectedOrganization, selectedTargetGroup, selectedCategory]);

  const handleOfferClick = (offerId: string) => {
    navigate(`/angebote/${offerId}`);
  };

  // Helper function to strip HTML tags from the description
  function stripHtmlTags(html: string): string {
    const tmp = document.createElement('div');
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || '';
  }

const handleOrganizationChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
  const orgId = e.target.value;
  setSelectedOrganization(orgId);

  // Update the URL with the new organization_id
  const params = new URLSearchParams(location.search);
  if (orgId) {
    params.set('organization_id', orgId);
  } else {
    params.delete('organization_id');
  }
  navigate({
    pathname: location.pathname,
    search: params.toString(),
  });
};


  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-start">Verfügbare Angebote</h2>

      {/* Filter Section */}
      <div className="row mb-4">
        <div className="col-md-4">
          <label htmlFor="organization" className="form-label">
            Nach Organisation filtern
          </label>
          <select
            id="organization"
            className="form-select"
            value={selectedOrganization}
            onChange={handleOrganizationChange}
          >
            <option value="">Alle Anbieter</option>
            {organizations.map((org) => (
              <option key={org.id} value={org.id}>
                {org.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-4">
          <label htmlFor="targetGroup" className="form-label">
            Nach Zielgruppe filtern
          </label>
          <select
            id="targetGroup"
            className="form-select"
            value={selectedTargetGroup}
            onChange={(e) => setSelectedTargetGroup(e.target.value)}
          >
            <option value="">Alle Zielgruppen</option>
            {targetGroups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-4">
          <label htmlFor="category" className="form-label">
            Nach Kategorie filtern
          </label>
          <select
            id="category"
            className="form-select"
            value={selectedCategory}
            onChange={(e) => setSelectedCategory(e.target.value)}
          >
            <option value="">Alle Kategorien</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      {/* Offer Cards */}
      <div className="row">
        {filteredOffers.map((offer) => {
          const organization = organizationMap[offer.organization_id];

          return (
            <div className="col-md-4 mb-4" key={offer.id}>
              <div
                className="card shadow-lg h-100 border-0"
                style={{ borderRadius: '10px', cursor: 'pointer' }}
                onClick={() => handleOfferClick(offer.id)}
              >
                {offer.picture_url ? (
                  <img
                    src={offer.picture_url}
                    alt={offer.title}
                    className="card-img-top"
                    style={{
                      height: '140px',
                      objectFit: 'contain',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      padding: "8px",
                    }}
                  />
                ) : organization && organization.logo_url ? (
                  <img
                    src={organization.logo_url}
                    alt={organization.name}
                    className="card-img-top"
                    style={{
                      height: '140px',
                      objectFit: 'contain',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      padding: "8px",
                    }}
                  />
                ) : (
                  <div
                    className="card-img-placeholder"
                    style={{
                      height: '200px',
                      backgroundColor: '#f8f9fa',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#aaa',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                    }}
                  >
                    Kein Bild verfügbar
                  </div>
                )}
                <div className="card-body">
                  <h5 className="card-title">{offer.title}</h5>
                  <p className="card-text" style={{ color: '#555' }}>
                    {/* Remove HTML tags and truncate description */}
                    {stripHtmlTags(offer.description).slice(0, 100)}...
                  </p>
                  <p className="text-muted">
                    {offer.city}, {offer.street} {offer.house_number}
                  </p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OfferList;