import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="container mt-4">
            <h1 className="mb-4">Datenschutzhinweise</h1>

            <h2>Datenschutz-Hinweise für den Dienst Frühe Hilfen des Landkreis Waldshut</h2>

            <h3>Inhaltsübersicht</h3>
            <ol>
                <li><a href="#gegenstand">Gegenstand</a></li>
                <li><a href="#verantwortliche">Name und Kontaktdaten der für die Verarbeitung Verantwortlichen sowie der Datenschutzbeauftragten</a></li>
                <li><a href="#sichtbarkeit">Sichtbarkeit Ihrer Profildaten</a></li>
                <li>
                    Einzelne Funktionen
                    <ol type="a">
                        <li><a href="#kontaktformular">Kontaktformular</a></li>
                        <li><a href="#darstellung-vermittlung">Darstellung und Vermittlung von Gesundheitsleistungen</a></li>
                    </ol>
                </li>
                <li>
                    Datenverarbeitung zu Analyse-Zwecken
                    <ol type="a">
                        <li><a href="#server-logfiles">Server Log-Files (Web-Oberfläche)</a></li>
                        <li><a href="#nutzungsstatistiken">Nutzungsstatistiken</a></li>
                    </ol>
                </li>
                <li>
                    Ergänzende Hinweise zur Bereitstellungspflicht, Rechtsgrundlage, zu Verarbeitungszwecken, Datenempfängern und Speicherdauer
                    <ol type="a">
                        <li><a href="#bereitstellungspflicht">Bereitstellungspflicht</a></li>
                        <li><a href="#rechtsgrundlage">Rechtsgrundlage und Nutzungszwecke</a></li>
                        <li><a href="#datenempfaenger">Datenempfänger</a></li>
                        <li><a href="#speicherdauer">Speicherdauer</a></li>
                    </ol>
                </li>
                <li>
                    Ihre DSGVO-Rechte
                    <ol type="a">
                        <li><a href="#auskunft">Auskunft</a></li>
                        <li><a href="#berichtigung">Berichtigung</a></li>
                        <li><a href="#loeschen">Löschen</a></li>
                        <li><a href="#einschraenkung">Einschränkung der Verarbeitung</a></li>
                        <li><a href="#datenuebertragbarkeit">Datenübertragbarkeit</a></li>
                        <li><a href="#beschwerde">Beschwerde</a></li>
                        <li><a href="#widerruf">Widerruf (von Einwilligungen)</a></li>
                        <li><a href="#widerspruchsrecht">Widerspruchsrecht</a></li>
                    </ol>
                </li>
                <li><a href="#datensicherheit">Datensicherheit</a></li>
                <li><a href="#aktualitaet">Aktualität und Änderung dieser Datenschutzinformation</a></li>
                <li>
                    Anhang: Begriffserläuterungen
                    <ol type="a">
                        <li><a href="#begriffe">Begriffe</a></li>
                        <li><a href="#rechtsgrundlagen">Rechtsgrundlagen</a></li>
                    </ol>
                </li>
            </ol>

            <h2 id="gegenstand">1. Gegenstand</h2>
            <p>
                Mit diesen Datenschutzhinweisen informieren wir Sie über die Verarbeitung Ihrer personenbezogenen Daten durch uns, wenn Sie die Homepage{' '}
                <a href="https://www.familien-plus.de" target="_blank" rel="noopener noreferrer">
                    https://www.familien-plus.de
                </a>{' '}
                und/oder die App „Familien-plus“ aufrufen.
            </p>

            <h2 id="verantwortliche">2. Name und Kontaktdaten der für die Verarbeitung Verantwortlichen sowie der Datenschutzbeauftragten</h2>
            <p>
                Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
            </p>
            <p>
                Landkreis Waldshut<br />
                Der Landrat<br />
                Kaiserstraße 110<br />
                797961 Waldshut-Tiengen
            </p>
            <p>
                Telefon: 07751 – 86 0<br />
                Fax: 07751 - 861999<br />
                E-Mail: <a href="mailto:post@landkreis-waldshut.de">post@landkreis-waldshut.de</a>
            </p>
            <p>
                Die Datenschutzbeauftragte des Landkreises ist unter
            </p>
            <p>
                Landkreis Waldshut<br />
                Datenschutzbeauftragte<br />
                Kaiserstraße 110<br />
                797961 Waldshut-Tiengen
            </p>
            <p>
                Telefon: 07751 867200<br />
                E-Mail: <a href="mailto:datenschutz@landkreis-waldshut.de">datenschutz@landkreis-waldshut.de</a>
            </p>

            <h2 id="sichtbarkeit">3. Sichtbarkeit Ihrer Daten</h2>
            <p>
                Folgende Daten sind für andere Besucher von{' '}
                <a href="https://www.familien-plus.de" target="_blank" rel="noopener noreferrer">
                    https://www.familien-plus.de
                </a>{' '}
                (auch nicht registrierte Nutzer) sichtbar:
            </p>
            <ul>
                <li>Name der Organisation (sofern Organisation)</li>
                <li>Profilbild (sofern hinterlegt)</li>
                <li>Textuelle Profilbeschreibung (sofern hinterlegt)</li>
                <li>Kontakt-E-Mail-Adresse (sofern hinterlegt)</li>
                <li>Webseite (sofern hinterlegt)</li>
                <li>Telefonnummer (sofern hinterlegt)</li>
                <li>Registrierdatum</li>
                <li>Eventuelle weitere freiwillige Angaben</li>
            </ul>

            <h2 id="einzelne-funktionen">4. Einzelne Funktionen</h2>
            <p>
                Nachfolgend erläutern wir den Umgang mit Ihren Daten, wenn Sie einzelne Funktionen von{' '}
                <a href="https://www.familien-plus.de" target="_blank" rel="noopener noreferrer">
                    https://www.familien-plus.de
                </a>{' '}
                nutzen.
            </p>

            <h3 id="kontaktformular">4.1. Kontaktformular</h3>
            <p>
                Wir bieten Ihnen die Möglichkeit, mit uns über ein Formular Kontakt aufzunehmen. Dabei sind die folgenden Angaben als Pflichtangaben erforderlich:
                Name, Vorname, E-Mail-Adresse, Betreff und Nachricht.
            </p>
            <p>
                Ihre Daten benötigen wir, um festzustellen von wem die Anfrage stammt und um diese beantworten und bearbeiten zu können.
            </p>
            <p>
                Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist im Rahmen der Beantwortung einer Kontaktanfrage auf unserer Aufgabenerfüllung gem. Art. 6 Abs. 1
                S. 1 lit. e Datenschutz-Grundverordnung (DSGVO) gestützt. Falls Sie registrierter Nutzer sind und sich die Anfrage auf die Nutzungsvereinbarung bezieht,
                ist Rechtsgrundlage für die Datenverarbeitung die Vertragsdurchführung gem. Art. 6 Abs. 1 S. 1 lit. b DSGVO.
            </p>
            <p>
                Die für die Benutzung des Kontaktformulars von uns erhobenen personenbezogenen Daten werden nach Erledigung der von Ihnen gestellten Anfrage gelöscht,
                sofern keine gesetzlichen Aufbewahrungsfristen einer Löschung Ihrer Daten entgegenstehen.
            </p>

            <h3 id="darstellung-vermittlung">4.2. Darstellung und Vermittlung von Gesundheitsleistungen</h3>
            <p>
                Die Webseite enthält Funktionen, um verfügbare Sozial- und Gesundheitsleistungen, insbesondere für Schwangere, junge Eltern und andere Interessierte,
                transparent zu machen und so Leistungserbringer und Nutzende der Plattform miteinander in Kontakt zu bringen.
            </p>
            <p>
                Hierzu können Leistungserbringer ihr Leistungsangebot darstellen. Diese Angaben sind für alle anderen Besucher der Plattformen sichtbar, auch für
                nicht-registrierte Nutzer. Die Angaben sind also auch ohne Registrierung unmittelbar zugänglich.
            </p>
            <p>
                Wenn Sie als Leistungserbringer einzelne Leistungsangebote einstellen, erfassen wir die hierzu von Ihnen eingetragene Beschreibung. Diese ist für alle
                Besucher der Plattformen sichtbar. Sie können die Löschung ihres Leistungsangebots jederzeit beantragen. Nehmen Sie hierzu Kontakt mit dem Betreiber auf.
            </p>
            <p>
                Wenn Sie ein Angebot in Anspruch nehmen wollen, können Sie den Leistungserbringer über die von ihm angegebenen Kanäle kontaktieren (z.B. telefonisch oder
                Vor-Ort). Es erfolgt keine Kontaktaufnahme oder Buchung von Leistungen über unsere Webseite.
            </p>
            <p>
                Bitte nutzen Sie nicht das Kontaktformular der Webseite oder eine Nachrichtenfunktion der Webseite zur Übermittlung von sensiblen Angaben, etwa zu Ihrem
                Gesundheitszustand, sondern kontaktieren Sie den Leistungserbringer direkt.
            </p>

            <h2 id="datenverarbeitung-analyse">5. Datenverarbeitung zu Analyse-Zwecken</h2>

            <h3 id="server-logfiles">5.1. Server Log-Files (Web-Oberfläche)</h3>
            <p>
                Wenn Sie eine einzelne Seite der Plattformen aufrufen, erfassen unsere Web-Server in einer Log-Datei die Adresse (URL) der abgerufenen Seite, Datum und
                Uhrzeit des Abrufs, etwaige Fehlermeldungen und ggf. das Betriebssystem und die Browser-Software Ihres Endgerätes sowie die Webseiten, von denen aus Sie
                uns besuchen. Wir speichern in unseren Logfiles auch die IP-Adresse Ihres Rechners.
            </p>
            <p>
                Die Log-File-Daten werden von uns ausschließlich zur Sicherstellung der Funktionsfähigkeit unserer Dienste verwendet (z. B. Fehleranalyse, Gewährleistung
                der Systemsicherheit und Schutz vor Missbrauch) und nach sieben (7) Tagen gelöscht oder so verkürzt, dass kein Personenbezug mehr herstellbar ist.
            </p>

            <h3 id="nutzungsstatistiken">5.2. Nutzungsstatistiken</h3>
            <p>
                Wir erfassen anonyme Nutzungsstatistiken darüber, welche Funktionen und Seiten wie häufig genutzt wurden. Hierbei handelt es sich um einfache Zähler. Es
                erfolgt keine Zuordnung zu Ihrem Gerät oder Ihrem Nutzungskonto oder Ihrem Namen und es werden keine pseudonymen Profile erstellt. Für die Erstellung von
                Nutzungsstatistiken werden keine Drittdienstleister eingesetzt.
            </p>
            <p>
                Wir respektieren die „Do not track Einstellung“ Ihres Browsers: Sollten Sie bei Ihrem Browser eingestellt haben, dass keine Nachverfolgung erfolgen soll,
                erfassen wir keine Nutzungsstatistiken.
            </p>

            <h2 id="ergaenzende-hinweise">6. Ergänzende Hinweise zur Bereitstellungspflicht, Rechtsgrundlage, zu Verarbeitungszwecken, Datenempfängern und Speicherdauer</h2>
            <p>
                Soweit in diesen Datenschutzhinweisen nicht anders ausgeführt gilt:
            </p>

            <h3 id="bereitstellungspflicht">6.1. Bereitstellungspflicht</h3>
            <p>
                Sie sind nicht zur Bereitstellung von Daten verpflichtet, wenn Sie die Webseite nur besuchen möchten. Pflichtangaben in Eingabeformularen sind als solche
                gekennzeichnet, z. B. durch ein Sternchen (*).
            </p>

            <h3 id="rechtsgrundlage">6.2. Rechtsgrundlage und Nutzungszwecke</h3>
            <p>
                Die Plattformen dienen der Erfüllung unserer gesetzlichen Aufgaben der Nutzergruppen der Frühen Hilfen. Zweck der Webseite ist die Sichtbarkeitsmachung
                von Angeboten, insbesondere für Schwangere und junge Eltern sowie die Erleichterung der Wahrnehmung dieser Angebote. Hierdurch soll eine Verbesserung der
                Informationsmöglichkeiten für unsere Nutzergruppen sichergestellt werden. Die Bereitstellung durch uns erfolgt freiwillig, ebenso die Nutzung durch Sie.
                Rechtsgrundlage ist die Wahrnehmung einer Aufgabe, die im öffentlichen Interesse liegt (Art. 6 Abs. 1 lit. e DSGVO).
            </p>
            <p>
                Sofern Sie registrierter Nutzer sind, dient die Datenverarbeitung auch der Bereitstellung der jeweiligen Funktionen. Rechtsgrundlage ist insofern die
                Durchführung des Nutzungsverhältnisses zwischen Ihnen und uns (Art. 6 Abs. 1 lit. b DSGVO).
            </p>

            <h3 id="datenempfaenger">6.3. Datenempfänger und Auftragsverarbeiter</h3>
            <p>
                Ihre Daten erhalten innerhalb des Landkreises die jeweils fachlich zuständigen Stellen, z. B. die mit der inhaltlich/redaktionellen Betreuung
                verantwortlichen Mitarbeitenden.
            </p>
            <p>
                Die Plattformen werden technisch betrieben durch CloudHaus (CloudHaus GmbH, Sandweg 10, 38524 Sassenburg). CloudHaus kann seinerseits technische
                Dienstleister einsetzen für das Hosting der Plattformen.
            </p>

            <h3 id="speicherdauer">6.4. Speicherdauer</h3>
            <p>
                Wir bemessen die Speicherdauer für Ihre Daten anhand der konkreten Zwecke, zu denen wir die Daten verwenden. Dabei werden Ihre Daten mindestens so lange
                gespeichert, bis das entsprechende Profil/Angebot gelöscht wird. Darüber hinaus unterliegen wir gesetzlichen Aufbewahrungs- und Dokumentationspflichten.
                Die Speicherdauer beurteilt sich auch nach den gesetzlichen Verjährungsfristen, §§ 195 ff. des Bürgerlichen Gesetzbuchs (BGB).
            </p>

            <h2 id="ihre-dsgvo-rechte">7. Ihre Rechte nach der Datenschutz-Grundverordnung</h2>

            <h3 id="auskunft">7.1. Auskunft</h3>
            <p>
                Sie haben das Recht, von uns eine Bestätigung darüber zu verlangen, ob wir Sie betreffende personenbezogene Daten verarbeiten; ist dies der Fall, so haben
                Sie ein Recht auf Auskunft über diese personenbezogenen Daten und auf die in Art. 15 DSGVO im Einzelnen aufgeführten Informationen.
            </p>

            <h3 id="berichtigung">7.2. Berichtigung</h3>
            <p>
                Sie haben das Recht, von uns unverzüglich die Berichtigung Sie betreffender unrichtiger personenbezogener Daten und ggf. die Vervollständigung
                unvollständiger personenbezogener Daten zu verlangen, Art. 16 DSGVO.
            </p>

            <h3 id="loeschen">7.3. Löschen</h3>
            <p>
                Sie haben das Recht, von uns zu verlangen, dass Sie betreffende personenbezogene Daten unverzüglich gelöscht werden, sofern einer der in Art. 17 DSGVO im
                Einzelnen aufgeführten Gründe zutrifft, z. B., wenn die Daten für die verfolgten Zwecke nicht mehr benötigt werden.
            </p>

            <h3 id="einschraenkung">7.4. Einschränkung der Verarbeitung</h3>
            <p>
                Sie haben das Recht, von uns die Einschränkung der Verarbeitung zu verlangen, wenn eine der in Art. 18 DSGVO aufgeführten Voraussetzungen gegeben ist, z.
                B., wenn Sie Widerspruch gegen die Verarbeitung eingelegt haben, für die Dauer der Prüfung durch uns.
            </p>

            <h3 id="datenuebertragbarkeit">7.5. Datenübertragbarkeit</h3>
            <p>
                Sie haben das Recht, unter bestimmten Voraussetzungen Sie betreffende Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten, zu übermitteln und - soweit technisch machbar - übermitteln zu lassen, Art. 20 DSGVO.
            </p>

            <h3 id="beschwerde">7.6. Beschwerde</h3>
            <p>
                Sie haben unabhängig von anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfen das Recht auf Beschwerde bei einer Aufsichtsbehörde, wenn
                Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten durch uns gegen die DSGVO verstößt, Art. 77 DSGVO. Zuständige
                Aufsichtsbehörde ist
            </p>
            <p>
                Der Landesbeauftragte für den Datenschutz und die Informationsfreiheit Baden-Württemberg<br />
                Lautenschlagerstraße 20<br />
                709173 Stuttgart
            </p>
            <p>
                Telefon: 0711 615541-0<br />
                E-Mail: <a href="mailto:poststelle@lfdi.bwl.de">poststelle@lfdi.bwl.de</a>
            </p>

            <h3 id="widerruf">7.7. Widerruf (von Einwilligungen)</h3>
            <p>
                Wenn Sie uns eine Datenschutz-Einwilligung erteilt haben, haben Sie das Recht, diese jederzeit mit Wirkung für die Zukunft zu widerrufen.
            </p>

            <h3 id="widerspruchsrecht">7.8. Widerspruchsrecht</h3>
            <p>
                Sie haben das Recht, aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit gegen die Verarbeitung Sie betreffender personenbezogener
                Daten Widerspruch einzulegen.
            </p>
            <p>
                Legen Sie Widerspruch ein, werden wir Ihre personenbezogenen Daten nicht mehr verarbeiten, es sei denn, wir können zwingende schutzwürdige Gründe für die
                Verarbeitung nachweisen, die Ihre Interessen, Rechte und Freiheiten überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung oder Verteidigung
                von Rechtsansprüchen.
            </p>
            <p>
                Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an die oben genannte Adresse des Landkreises.
            </p>

            <h2 id="datensicherheit">8. Datensicherheit</h2>
            <p>
                Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein üblichen und sicheren Standard TLS (Transport Layer Security) verschlüsselt
                übertragen. TLS ist ein sicherer und erprobter Standard, der z. B. auch beim Onlinebanking Verwendung findet. Sie erkennen eine sichere TLS-Verbindung
                unter anderem an dem angehängten s am http (also https://...) in der Adressleiste Ihres Browsers oder am Schloss-Symbol im unteren Bereich Ihres Browsers.
            </p>
            <p>
                Wir bedienen uns geeigneter technischer und organisatorischer Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen,
                teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere Sicherheitsmaßnahmen werden entsprechend
                der technologischen Entwicklung fortlaufend verbessert.
            </p>

            <h2 id="aktualitaet">9. Aktualität und Änderung dieser Datenschutzinformation</h2>
            <p>
                Durch die Weiterentwicklung unserer Webseite und Angebote darüber oder aufgrund geänderter gesetzlicher bzw. behördlicher Vorgaben kann es notwendig
                werden, diese Datenschutzinformation zu ändern. Die jeweils aktuelle Datenschutzinformation kann jederzeit auf der Webseite von Ihnen abgerufen und
                ausgedruckt werden.
            </p>

            <h2 id="anhang">10. Anhang: Begriffserläuterungen</h2>

            <h3 id="begriffe">10.1. Begriffe</h3>
            <p>
                Im Folgenden erläutern wir einige in diesen Datenschutzhinweisen verwendete rechtliche und technische Begriffe.
            </p>
            <p>
                <strong>a) Auftragsverarbeiter:</strong><br />
                Auftragsverarbeiter sind Dienstleister, die Ihre Daten zweck- und weisungsgebunden nach unseren Vorgaben verarbeiten.
            </p>
            <p>
                <strong>b) Personenbezogene Daten:</strong><br />
                Personenbezogene Daten (Daten) sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche Person beziehen.
            </p>
            <p>
                <strong>c) Verarbeitung:</strong><br />
                Eine Verarbeitung personenbezogener Daten ist jeder Vorgang im Zusammenhang mit personenbezogenen Daten, z.B. das Erheben über ein Online-Formular, die
                Speicherung auf unseren Servern oder die Verwendung zur Kontaktaufnahme.
            </p>
            <p>
                <strong>d) IP-Adresse:</strong><br />
                Die IP-Adresse ist eine Nummer, die Ihr Internetanbieter Ihrem Endgerät vorübergehend oder auch dauerhaft zuweist. Mit einer vollständigen IP-Adresse ist
                es z. B. anhand von Zusatzinformationen Ihres Internetzugangsbetreibers im Einzelfall möglich, den Anschlussinhaber zu identifizieren.
            </p>

            <h3 id="rechtsgrundlagen">10.2. Rechtsgrundlagen</h3>
            <p>
                Die DSGVO erlaubt eine Verarbeitung personenbezogener Daten nur, wenn eine Rechtsgrundlage besteht. Wir sind gesetzlich verpflichtet, Ihnen die
                Rechtsgrundlage für die Verarbeitung Ihrer Daten mitzuteilen.
            </p>
            <p>
                Im Folgenden erläutern wir Ihnen die dabei verwendeten Begrifflichkeiten.
            </p>
            <table className="table">
                <thead>
                    <tr>
                        <th>Rechtsgrundlage</th>
                        <th>Bezeichnung</th>
                        <th>Erläuterung</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Art. 6 Abs. 1 lit. a) DSGVO</td>
                        <td>Einwilligung</td>
                        <td>
                            Diese Rechtsgrundlage erlaubt die Verarbeitung, wenn und soweit Sie uns eine Einwilligung erteilt haben.
                        </td>
                    </tr>
                    <tr>
                        <td>Art. 6 Abs. 1 lit. b) DSGVO</td>
                        <td>Vertragserfüllung</td>
                        <td>
                            Diese Rechtsgrundlage gestattet die Verarbeitung, soweit diese zur Erfüllung eines Vertrags mit Ihnen erforderlich ist, einschließlich
                            vorvertraglicher Maßnahmen (z. B. Durchführung der Nutzungsbedingungen).
                        </td>
                    </tr>
                    <tr>
                        <td>Art. 6 Abs. 1 lit. e) DSGVO</td>
                        <td>Aufgabenerfüllung</td>
                        <td>
                            Diese Rechtsgrundlage gestattet die Verarbeitung, soweit diese für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse
                            liegt oder in Ausübung öffentlicher Gewalt erfolgt, die uns übertragen wurde.
                        </td>
                    </tr>
                </tbody>
            </table>

            <p className="mt-4">
                <em>Stand: 20.08.2024</em>
            </p>
        </div>
    );
};

export default PrivacyPolicy;