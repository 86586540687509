import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const Header: React.FC = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <header className="bg-primary text-white py-3">
      <div className="header-container d-flex justify-content-between align-items-center px-3">
        <div className="d-flex align-items-center">
          <button
            className="menu-toggle d-lg-none me-3"
            onClick={toggleSidebar}
            aria-label="Toggle Menu"
          >
            <span className="menu-icon">&#9776;</span>
          </button>
          <h1 className="h4 mb-0">
            <Link to="/" className="text-white text-decoration-none">
              FamilienPlus
            </Link>
          </h1>
          <div className="image-wrapper ms-2">
            <img
              className="image-spacing shadowing"
              src="/logo512.png"
              width="40px"
              height="40px"
              alt="Logo"
            />
          </div>
        </div>
        <nav className="d-none d-lg-flex">
          <Link to="/" className="text-white text-decoration-none mx-3">Startseite</Link>
          <Link to="/kompass" className="text-white text-decoration-none mx-3">Kompass</Link>
          <Link to="/angebote" className="text-white text-decoration-none mx-3">Angebote</Link>
          <Link to="/liste-anbieter" className="text-white text-decoration-none mx-3">Anbieter</Link>
        </nav>
      </div>
      {/* Sidebar menu for mobile */}
      <div className={`sidebar bg-primary ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-sidebar" onClick={toggleSidebar} aria-label="Close Menu">
          &times;
        </button>
        <nav className='align-items-space-between'>
          <div>
            {/* Main Navigation Links */}
            <Link to="/" className="text-white text-decoration-none d-block py-2" onClick={toggleSidebar}>
              Startseite
            </Link>
            <Link to="/kompass" className="text-white text-decoration-none d-block py-2" onClick={toggleSidebar}>
              Kompass
            </Link>
            <Link to="/angebote" className="text-white text-decoration-none d-block py-2" onClick={toggleSidebar}>
              Angebote
            </Link>
            <Link to="/liste-anbieter" className="text-white text-decoration-none d-block py-2" onClick={toggleSidebar}>
              Anbieter
            </Link>
          </div>
          <div className="mt-3">
            <a
              href="https://www.landkreis-waldshut.de/"
              className="text-white text-decoration-none d-block py-1"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleSidebar}
            >
              Landkreis Waldshut
            </a>
            <a
              href="https://www.landkreis-waldshut.de/impressum/"
              className="text-white text-decoration-none d-block py-1"
              target="_blank"
              rel="noopener noreferrer"
              onClick={toggleSidebar}
            >
              Impressum
            </a>
            <Link
              to="/datenschutz"
              className="text-white text-decoration-none d-block py-1"
              onClick={toggleSidebar}
            >
              Datenschutz
            </Link>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Header;