import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ArticleCategories from './components/ArticleCategories';
import ArticleList from './components/ArticleList';
import ArticleDetail from './components/ArticleDetails';
import OfferList from './components/OfferList';
import OfferDetail from './components/OfferDetail';
import OrganizationList from './components/OrganizationList';
import PrivacyPolicy from './components/Datenschutz';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './components/Homepage';
import 'bootstrap/dist/css/bootstrap.min.css';
import './custom-bootstrap.scss';

const App: React.FC = () => {
  return (
    <Router>
      <div className="d-flex flex-column bg-light min-vh-100">
        <Header />
        <main className="flex-grow-1 bg-light">
          <Routes>
            <Route path="/kompass" element={<ArticleCategories />} />
            <Route path="/kategorie/:categoryId" element={<ArticleList />} />
            <Route path="/artikel/:articleId" element={<ArticleDetail />} />
            <Route path="/angebote" element={<OfferList />} />
            <Route path="/angebote/:offerId" element={<OfferDetail />} />
            <Route path="/liste-anbieter" element={<OrganizationList />} />
            <Route path="/datenschutz" element={<PrivacyPolicy />} />
            <Route path="/" element={<Homepage />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
