import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Article } from '../types';

const ArticleDetail: React.FC = () => {
  const { articleId } = useParams<{ articleId: string }>();
  const [article, setArticle] = useState<Article | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://waldshut.progresspioneer.com/api/articles/${articleId}`)
      .then(response => setArticle(response.data))
      .catch(error => console.error('Error fetching article:', error));
  }, [articleId]);

  if (!article) return <div className="text-center mt-5">Loading...</div>;

  return (
    <div className="container mt-5">
      <button className="btn btn-secondary mb-4 text-white" onClick={() => navigate(-1)}>
        Zurück
      </button>
      <div className="card shadow p-4 mb-5 border-0" style={{ borderRadius: "1.5 rem" }}>
        {/* {article.picture_url ? (
          <img
            src={article.picture_url}
            alt={article.title}
            className="me-3 mb-5"
            style={{ width: '100%', height: '200px', objectFit: 'cover', minWidth: '200px' }}
          />
        ) : (
          <div
            className="me-3 bg-light mb-5 d-flex align-items-center justify-content-center"
            style={{ width: '100%', height: '200px', minWidth: '250px' }}
          >
            <span className="text-black">Kein Bild</span>
          </div>
        )} */}
        <h2 className="card-title text-start mb-2">{article.title}</h2>
        <p className="text-start text-muted mb-4">
          Veröffentlicht am {new Date(article.created).toLocaleDateString()}
        </p>
        <div> <style>
          {`
            .content a {
              text-decoration: none;
              color: #a5c814 !important;
              transition: color 0.2s ease;
            }

            .content a:hover, .content a:focus {
              color: #a5c814 !important;
              text-decoration: underline; /* Optional */
            }
          `}

        </style>

          <div className="card-body">
            <div className="content" dangerouslySetInnerHTML={{ __html: article.content }} />
          </div>
        </div>
      </div>
    </div >

  );
};

export default ArticleDetail;
