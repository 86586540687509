import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { ArticleCategory } from '../types';

const ArticleCategories: React.FC = () => {
  const [categories, setCategories] = useState<ArticleCategory[]>([]);

  useEffect(() => {
    axios.get('https://waldshut.progresspioneer.com/api/articlecategories')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  return (
    <div className="container mt-5">
      <h2 className="mb-4 text-start">Kompass</h2>
      <div className="row g-4">
        {categories.map((category) => (
          <div className="col-md-4" key={category.id}>
            <Link to={`/kategorie/${category.id}`} className="text-decoration-none">
              <div className="position-relative">
                <img
                  src={category.picture_url || 'https://via.placeholder.com/300x200'}
                  alt=""
                  className="blurred-background position-absolute top-0 left-0 w-100 h-100"
                  style={{
                    objectFit: 'cover',
                    filter: 'blur(8px)',
                    zIndex: 1,
                  }}
                />
                <div className="card position-relative" style={{ zIndex: 2 }}>
                  <img
                    src={category.picture_url || "https://via.placeholder.com/300x200"}
                    alt={category.name}
                    className="card-img border-0"
                    style={{
                      height: "300px",
                      objectFit: "cover",
                    }}
                  />
                  <div
                    className="position-absolute bottom-0 start-0 w-100"
                    style={{
                      background:
                        "linear-gradient(to top, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0))",
                      padding: "20px",
                    }}
                  >
                    <h5 className="card-title mb-2 text-white">{category.name}</h5>
                    <p className="card-text text-white d-flex align-items-center">
                      Artikel ansehen <span className="ms-2">→</span>
                    </p>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        ))}
      </div>
    </div>

  );
};

export default ArticleCategories;
