import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="bg-white py-4 border-top mt-5">
      <div className="container">
        <div className="row align-items-center text-center text-md-start">
          {/* Footer Brand */}
          <div className="col-12 col-md-auto mb-3 mb-md-0">
            <h5 className="text-uppercase mb-0">FamilienPlus</h5>
          </div>
          {/* Footer Links */}
          <div className="col-12 col-md d-flex justify-content-center justify-content-md-start mb-3 mb-md-0">
            <ul className="list-inline mb-0">
              <li className="list-inline-item mx-2">
                <a
                  href="https://www.landkreis-waldshut.de/"
                  className="text-decoration-none text-dark link-primary"
                >
                  Landkreis Waldshut
                </a>
              </li>
              <li className="list-inline-item mx-2">
                <a
                  href="https://www.landkreis-waldshut.de/impressum/"
                  className="text-decoration-none text-dark link-primary"
                >
                  Impressum
                </a>
              </li>
              <li className="list-inline-item mx-2">
                <a
                  href="/datenschutz"
                  className="text-decoration-none text-dark link-primary"
                >
                  Datenschutz
                </a>
              </li>
            </ul>
          </div>
          {/* Footer Image and Copyright */}
          <div className="col-12 col-md-auto d-flex flex-column flex-md-row align-items-center justify-content-md-end">
            <img src="/LogoWaldshut.png" width={100} className="mb-2 mb-md-0 me-md-2" alt="Logo Waldshut" />
            <p className="mb-0">© {new Date().getFullYear()} Landkreis Waldshut</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;