import React, { useEffect, useState } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Article } from '../types';

const ArticleList: React.FC = () => {
  const { categoryId } = useParams<{ categoryId: string }>();
  const [articles, setArticles] = useState<Article[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(`https://waldshut.progresspioneer.com/api/articles/category/${categoryId}`)
      .then(response => setArticles(response.data))
      .catch(error => console.error('Error fetching articles:', error));
  }, [categoryId]);

  return (
    <div className="container mt-5 bg-light">
      <button className="btn btn-secondary text-white mb-4" onClick={() => navigate(-1)}>
        Zurück
      </button>
      <h2 className="mb-4">Artikel</h2>
      <ul className="list-group gap-4">
        {articles.map((article) => {
          return (
            <li
              key={article.id}
              className="list-group-item p-4 border-0 shadow rounded-4 d-flex align-items-start position-relative"
              style={{
                borderRadius: '2rem',
                maxHeight: '140px',
                overflow: 'hidden',
              }}
            >

              {/* {
                article.picture_url ? (
                  <img
                    src={article.picture_url}
                    alt={article.title}
                    className="me-3 rounded-circle"
                    style={{ width: '100px', height: '100px', objectFit: 'cover', minWidth: '100px' }}
                  />
                ) : (
                  <div
                    className="me-3 bg-light rounded-circle d-flex align-items-center justify-content-center"
                    style={{ width: '100px', height: '100px', minWidth: '100px' }}
                  >
                    <span className="text-black">Kein Bild</span>
                  </div>
                )
              } */}
              {/* Article content */}
              <div>
                <Link
                  to={`/artikel/${article.id}`}
                  className="text-decoration-none text-dark"
                >
                  <h5 className="mb-2">{article.title}</h5>
                  <p
                    className="mb-0 text-muted"
                    dangerouslySetInnerHTML={{
                      __html: article.content.slice(0, 350) + '...',
                    }}
                  />
                </Link>
              </div>
            </li>
          );
        })}
      </ul>
    </div >

  );
};

export default ArticleList;
