import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface Organization {
  id: string;
  name: string;
  logo_url: string | null;
  banner_url: string | null;
  website: string | null;
  phone: string | null;
  zip_code: string | null;
  city: string | null;
  street: string | null;
  house_number: string | null;
  qualification: string | null;
  focus: string | null;
}

const OrganizationList: React.FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [filteredOrganizations, setFilteredOrganizations] = useState<Organization[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('name'); // Default sorting by name
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get('https://waldshut.progresspioneer.com/api/organization')
      .then((res) => {
        setOrganizations(res.data);
        setFilteredOrganizations(res.data); // Set the filtered list initially to the full list
      })
      .catch((err) => console.error('Error fetching organizations:', err));
  }, []);

  // Filter and sort organizations when the search term or sort option changes
  useEffect(() => {
    let updatedOrganizations = [...organizations];

    // Filter by search term
    if (searchTerm) {
      updatedOrganizations = updatedOrganizations.filter((org) =>
        org.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Sort by selected option
    updatedOrganizations.sort((a, b) => {
      const aValue = (a[sortOption as keyof Organization] || '').toString();
      const bValue = (b[sortOption as keyof Organization] || '').toString();
      return aValue.localeCompare(bValue);
    });

    // If descending, reverse the array
    if (sortDirection === 'desc') {
      updatedOrganizations.reverse();
    }

    setFilteredOrganizations(updatedOrganizations);
  }, [searchTerm, sortOption, sortDirection, organizations]);

  const toggleSortDirection = () => {
    setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
  };

  return (
    <div className="container mt-4">
      <h2 className="mb-4 text-start">Anbieter</h2>

      {/* Filter and Sort Options */}
      <div className="row mb-4">
        <div className="col-md-4">
          <input
            type="text"
            className="form-control"
            placeholder="Nach Anbieter suchen"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
        <div className="col-md-4 d-flex align-items-center">
          <select
            className="form-select me-2"
            value={sortOption}
            onChange={(e) => setSortOption(e.target.value)}
          >
            <option value="name">Nach Namen sortieren</option>
            <option value="city">Nach Stadt sortieren</option>
            <option value="zip_code">Nach PLZ sortieren</option>
          </select>
          <button
            type="button"
            className="btn btn-outline-secondary"
            onClick={toggleSortDirection}
          >
            {sortDirection === 'asc' ? '▲' : '▼'}
          </button>
        </div>
      </div>

      {/* Organization Cards */}
      <div className="row g-4">
        {filteredOrganizations.map((org) => {
          const fullAddress = `${org.street || ''} ${org.house_number || ''}, ${org.zip_code || ''} ${org.city || ''}`.trim();

          return (
            <div className="col-md-6 col-lg-6" key={org.id}>
              <div
                className="card shadow-lg h-100 border-0"
                style={{ borderRadius: '10px', cursor: 'pointer' }}
                onClick={() => navigate(`/angebote?organization_id=${org.id}`)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    navigate(`/angebote?organization_id=${org.id}`);
                  }
                }}
              >
                {org.banner_url ? (
                  <img
                    src={org.banner_url}
                    alt={org.name}
                    className="card-img-top"
                    style={{
                      height: '140px',
                      objectFit: 'contain',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      padding: '8px'
                    }}
                  />
                ) : org.logo_url ? (
                  <img
                    src={org.logo_url}
                    alt={org.name}
                    className="card-img-top"
                    style={{
                      height: '140px',
                      objectFit: 'contain',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      padding: '8px'
                    }}
                  />
                ) : (
                  <div
                    style={{
                      height: '200px',
                      backgroundColor: '#f5f5f5',
                      borderTopLeftRadius: '10px',
                      borderTopRightRadius: '10px',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: '#aaa',
                    }}
                  >
                    Kein Bild verfügbar
                  </div>
                )}

                <div className="card-body" style={{ padding: '20px', overflow: 'auto' }}>
                  <h5 className="card-title" style={{ fontSize: '1.25rem', fontWeight: 'bold', color: '#333' }}>
                    {org.name}
                  </h5>
                  {org.website && (
                    <p>
                      <strong>Webseite:</strong>{' '}
                      <a
                        href={
                          org.website.startsWith('http://') || org.website.startsWith('https://')
                            ? org.website
                            : `https://${org.website}`
                        }
                      >
                        {org.website}
                      </a>
                    </p>
                  )}
                  {org.phone && (
                    <p>
                      <strong>Telefonnummer:</strong>{' '}
                      <a href={`tel:${org.phone}`} className="text-decoration-none">
                        {org.phone}
                      </a>
                    </p>
                  )}
                  {fullAddress && fullAddress !== ', ,' && (
                    <p>
                      <strong>Adresse:</strong>{' '}
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          fullAddress
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-decoration-none"
                      >
                        {fullAddress}
                      </a>
                    </p>
                  )}
                  {org.qualification && <p><strong>Qualifikation:</strong> {org.qualification}</p>}
                  {org.focus && <p><strong>Fokus:</strong> {org.focus}</p>}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrganizationList;