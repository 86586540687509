import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

interface Offer {
  id: string;
  title: string;
  description: string;
  picture_url: string | null;
  city: string;
  street: string;
  house_number: string;
  zip_code: string;
  organization_id: string;
  termination_date: string | null;
  category_id: string;
  created: string;
  email_address: string | null;
  phone_number: string | null;
  requirements: string | null;
  target_group_id: string;
}

interface Organization {
  name: string;
  logo_url: string | null;
  website: string | null;
  phone: string | null;
  address: string | null;
}

const OfferDetail: React.FC = () => {
  const { offerId } = useParams<{ offerId: string }>();
  const [offer, setOffer] = useState<Offer | null>(null);
  const [organization, setOrganization] = useState<Organization | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch offer details
    axios
      .get(`https://waldshut.progresspioneer.com/api/offers/${offerId}`)
      .then((res) => {
        setOffer(res.data);
        console.log(res.data)
        return res.data.organization_id;
      })
      .then((organizationId) => {
        if (organizationId) {
          // Fetch organization details based on the organization_id
          axios
            .get(`https://waldshut.progresspioneer.com/api/organization/${organizationId}`)
            .then((res) => setOrganization(res.data));
        }
      })
      .catch((err) => console.error('Error fetching offer details:', err));
  }, [offerId]);

  if (!offer) return <div className="text-center mt-5">Laden...</div>;

  // Format termination date
  const formattedTerminationDate = offer.termination_date
    ? new Intl.DateTimeFormat('de-DE').format(new Date(offer.termination_date))
    : null;

  // Construct full address
  const fullAddress = `${offer.street} ${offer.house_number}, ${offer.zip_code} ${offer.city}`;

  return (
    <div className="container mt-4">
      <button className="btn btn-secondary mb-4 text-white" onClick={() => navigate(-1)}>
        zurück
      </button>
      <div className="card shadow-sm p-4 mb-5">
        {offer.picture_url && (
          <img
            src={offer.picture_url}
            alt={offer.title}
            className="card-img-top mb-3"
            style={{ maxHeight: '300px', objectFit: 'cover', borderRadius: '10px' }}
          />
        )}
        <h2 className="card-title">{offer.title}</h2>

        {/* Render the HTML content safely */}
        <div
          className="card-text"
          dangerouslySetInnerHTML={{ __html: offer.description }}
        />

        {/* Render Requirements if available */}
        {offer.requirements && (
          <p className="mt-3">
            <strong>Anforderungen:</strong> {offer.requirements}
          </p>
        )}

        {/* Display the termination date if it exists */}
        {formattedTerminationDate && (
          <p className="text-muted">
            <strong>Angebot gültig bis:</strong> {formattedTerminationDate}
          </p>
        )}

        {/* Contact Information */}
        <div className="mt-4">
          <h4>Kontaktinformationen</h4>

          {/* Address */}
          <p className="text-muted">
            <strong>Adresse:</strong>{' '}
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                fullAddress
              )}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-decoration-none"
            >
              {fullAddress}
            </a>
          </p>

          {/* Phone Number */}
          {offer.phone_number && (
            <p className="text-muted">
              <strong>Telefonnummer:</strong>{' '}
              <a href={`tel:${offer.phone_number}`} className="text-decoration-none">
                {offer.phone_number}
              </a>
            </p>
          )}

          {/* Email Address */}
          {offer.email_address && (
            <p className="text-muted">
              <strong>E-Mail:</strong>{' '}
              <a href={`mailto:${offer.email_address}`} className="text-decoration-none">
                {offer.email_address}
              </a>
            </p>
          )}
        </div>

        {/* Organization Information */}
        {organization && (
          <div className="mt-4">
            <h4>Organisationsinformationen</h4>
            <p>
              <strong>Name:</strong> {organization.name}
            </p>
            {organization.website && (
              <p>
                <strong>Webseite:</strong>{' '}
                <a
                  href={
                    organization.website.startsWith('http://') ||
                      organization.website.startsWith('https://')
                      ? organization.website
                      : `https://${organization.website}`
                  }
                  className="link-primary"
                >
                  {organization.website.replace(/(^\w+:|^)\/\//, '')}
                </a>
              </p>
            )}
            {organization.phone && (
              <p>
                <strong>Telefonnummer:</strong>{' '}
                <a href={`tel:${organization.phone}`} className="text-decoration-none">
                  {organization.phone}
                </a>
              </p>
            )}
            {organization.address && (
              <p>
                <strong>Adresse:</strong>{' '}
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                    organization.address
                  )}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  {organization.address}
                </a>
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default OfferDetail;